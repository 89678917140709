@media all and (max-width:767px){
    .main-content .main-navbar{
          
    height: 90px !important;
    padding: 5px 10px !important;
    
    }
    .main-navbar .container{
        max-width: 100% !important;
        padding: 0 30px !important;

    }
}


@media all and (max-width:600px){
    .react-responsive-modal-root{
        z-index: 8000 !important;
    } 
     .react-responsive-modal-modal {
         width: 100%!important;
     }
     .react-responsive-modal-container{
        padding-right: 30px!important;
     }
    
}